.contact-container {
    position: relative;
    background-color: var(--container-color);
    padding-block: 1rem;
}

.contact-data {
    position: relative;
    width: 100%;
    background-color: var(--container-color);
    padding: 3rem 1.5rem 3.5rem;
    margin-left: auto;
    border-bottom: 4px solid var(--first-color);
    z-index: 5;
    transition: background-color .4s;
}

.contact-data .geometric_box {
    top: 2rem;
    right: 2.5rem;
}

.contact-form-box {
    position: relative;
    background-color: var(--contact-color);
    padding: 5rem 1.5rem 2.5rem;
    margin-top: -2.5rem;
    transition: background-color .4s;
}

.contact-subtitle {
    color: var(--white-color);
    font-size: var(--h2-font-size);
    margin-bottom: 2rem;
    text-align: center;
}

.contact-form,
.contact-form-group {
    display: grid;
    row-gap: 1.25rem;
}

.contact-form {
    position: relative;
}

.contact-input-box {
    position: relative;
    width: 100%;
    height: 58px;
}

.contact-input,
.contact-button {
    font-size: var(--normal-font-size);
    outline: none;
    border: none;
}

.contact-input {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--black-color);
    border: 3px solid var(--black-color-light);
    color: var(--white-color);
    padding: .5rem 1rem;
    font-weight: var(--font-medium);
    transition: border .4s, background-color .4s;
}

.contact-input::placeholder {
    color: var(--text-color-light);
    transition: opacity .4s;
}

.contact-input:focus {
    border: 3px solid var(--white-color);
}

.contact-input:-webkit-autofill {
    transition: background-color 6000s, color 6000s;
}

.contact-label {
    position: absolute;
    top: 8px;
    left: 10px;
    color: var(--text-color-light);
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    background-color: var(--black-color);
    padding: 8px;
    pointer-events: none;
    transition: top .4s, opacity .4s, background-color .4s;
    opacity: 0;
}

.contact-label.dari {
    right: 10px;
    left: auto;
}

.contact-form .contact-area {
    height: 10rem;
}

.contact-area textarea {
    resize: none;
    padding-top: 1rem;
}

.contact-button {
    background-color: var(--first-color);
    cursor: pointer;
}

.contact-button:hover {
    color: black;
    background-color: var(--first-color-alt);
    cursor: pointer;
}

/* ~~~~ FOCUS PLACEHOLDER ~~~~ */
.contact-input:focus::placeholder {
    opacity: 0;
}

/* ~~~~ MOVE LABEL UP & STICKY LABEL~~~~ */
.contact-input:focus + .contact-label,
.contact-input:not(:placeholder-shown).contact-input:not(:focus) + .contact-label{
    opacity: 1;
    top: -16px;
}

.contact-social-box {
    display: grid;
    row-gap: 1rem;
    background-color: hsl(0, 0%, 10%);
    padding-block: 1rem 2rem;
    text-align: center;
}

.contact-social-arrow {
    width: 32px;
    margin: 0 auto;
}

.contact-social-data {
    display: flex;
    flex-direction: column;
}

.contact-social-title {
    color: var(--black-color-light);
    font-size: var(--small-font-size);
    margin-bottom: .3rem;
    text-align: center;
}

.contact-social-subtitle {
    color: var(--white-color);
    font-size: var(--small-font-size);
    font-weight: var(--font-semi-bold);
    text-align: center;
}

.contact-social-link-list {
    display: flex;
    justify-content: center;
    column-gap: .75rem;
}

.contact-social-link {
    background-color: var(--black-color-light);
    padding: 6px;
    color: var(--white-color);
    font-size: 1.25rem;
    display: grid;
    place-items: center;
    transition: background-color .3s;
}

.contact-social-link:hover {
    background-color: var(--first-color);
}

/* contact popup */
.contact-popup {
    text-align: left;
    font-size: var(--small-font-size);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.contact-popup-text {
    color: var(--white-color);
    font-size: var(--normal-font-size);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.contact-popup-icon {
    color: green;
    font-size: var(--h2-font-size);
}

/* -------------------- BREAKDOWN POINT --------------------*/
/* For small devices */
@media screen and (max-width: 340px) {
    .contact-data {
        width: 100%;
    }
}

/* For medium devices */
@media screen and (min-width: 576px) {
    .contact-data {
        background-color: var(--body-color);
        width: 350px;
        padding: 4rem 1.5rem;
        margin-inline: auto;
    }

    .contact-container {
        grid-template-columns: 500px;
        justify-content: center;
        padding-block: 4rem;
    }

    .contact-form-group {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
    }

    .contact-social-box {
        margin-top: 2rem;
        background-color: transparent;
    }

    .contact-social-arrow {
        filter: invert(1);
    }

    .contact-social-title {
        color: var(--text-color);
    }

    .contact-social-subtitle {
        color: var(--title-color);
    }

    .dark-theme .contact-social-arrow {
        filter: none;
    }
}

/* For Large Devices */
@media screen and (min-width: 1150px) {
    .contact-container {
        grid-template-columns: 440px 670px;
    }

    .contact-form-box {
        grid-column: 2 / 3;
        grid-row:  1 / 3;
        padding: 7rem 5.5rem 6rem 7.5rem;
        margin-top: 0;
    }

    .contact-subtitle {
        text-align: initial;
    }

    .contact-input-box {
        height: 60px;
    }

    .contact-form .contact-area {
        height: 11rem;
    }

    .contact-button {
        margin-top:  1.2rem;
        width: max-content;
    }

    .contact-data {
        width: 392px;
        height: 375px;
        padding: 4.5rem 3.5rem 3.5rem;
        margin: 7.5rem 0 0 8rem;
        border-bottom: 6px solid var(--first-color);
    }

    .contact-data .section_title-2 {
        margin: 0 0 2rem;
        transform: translateX(-6.5rem);
    }
    
    .contact-data .section_title-2.dari {
        margin: 0 0 0 8rem;
        transform: translateX(8rem);
    }

    .contact-social-box {
        margin-left: 8rem;
        text-align: initial;
    }
    

    .contact-social-arrow {
        width: 40px;
    }

    .contact-social-link {
        font-size: 1.5rem;
        padding: .5rem;
    }

    .contact-success-message {
        bottom: -2.5rem;
    }

    .dark-theme .contact-data {
        box-shadow: 4px 0 8px hsla(0, 0%, 4%, .3);
    }
}

select option:hover {
    background-color: #f1f1f1;
}