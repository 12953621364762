.international-section {
    background-color: var(--primary-dark-color);
}

.international-container {
    gap: .8rem;
    text-align: center;
}

.international-tittle {
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.international-description {
    color: var(--text-color);
    font-size: var(--h3-font-size);
}

.country-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.country-item {
    padding: 15px;
    text-align: center;
    transition: transform 0.5s ease-in-out;
}

.country-item:hover {
    transform: scale(1.1);
}

.country-item img {
    width: 70px;
    height: auto;
    display: block;
}

.country-name {
    margin-top: 10px;
    font-size: var(--smaller-font-size);
    color: var(--text-color);
    font-weight: var(--font-medium);
}

.international-footer-text {
    margin-top: 30px;
    color: var(--text-color);
    font-weight: var(--font-bold);
}