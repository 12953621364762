.testimonials-section {
    padding-block: 3.5rem;
    background-color: var(--container-color);

}

.testimonials-container {
    position: relative;
    padding-block: 2rem 1rem;
    padding-inline: 1rem;
    background-color: var(--body-color);
}

.testimonials-container .testimonials-subtitle {
    text-align: center;
}

.testimonials-left-quote,
.testimonials-right-quote {
    position: absolute;
    padding: var(--h3-font-size);
    font-size: var(--h3-font-size);
    background-color: var(--black-color);
    width: max-content;
    border-radius: 50%;
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonials-left-quote {
    top: -2rem;
    left: 1rem;
}

.testimonials-right-quote {
    bottom: -2rem;
    right: 1rem;
}

.testimonials-quote-icon {
    color: var(--white-color);
}

.testimonials-button-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .5rem;
    margin-top: 2rem;
    width: 100%;
}

.testimonials-swiper {
    margin-top: 2rem;
    width: 100%;
}

.testimonial-swiper-slide {
    text-align: center;
    display: flex;
    align-items: center;
    row-gap: .5rem;
    flex-direction: column;
}

.testimonial-text {
    font-size: var(--h3-font-size);
}

.testimonial-title {
    color: #0071BC;
    font-size: var(--small-font-size);
}

.testimonial-title::after,
.testimonial-title::before {
    background-color: #0071BC;
}

.testimonials-prev-button,
.testimonials-next-button {
    padding: var(--small-font-size);
    z-index: 10;
    touch-action: auto;
    cursor: pointer;
}

.testimonials-button-icon {
    font-size: var(--h3-font-size);
}

@media screen and (min-width: 768px) {
    .testimonials-section {
        padding-block: 4rem;
    }

    .testimonials-swiper {
        margin-top: 3rem;
    }
}