/* -------------------- ABOUT --------------------*/
.about_section {
    background-color: var(--container-color);
    transition: background-color .4s;
    padding-block: 1rem ;
}

.about-title {
    min-width: max-content;
}

.about-description-one {
    font-size: var(--h2-font-size);
    text-transform: uppercase;
    color: var(--title-color);
    text-align: center;
}

.about-description-two {
    font-size: var(--h3-font-size);
    background-color: var(--body-color);
    padding: 2rem 1rem;
    border-radius: 1rem;
    text-align: center;
    border-left: 4px solid var(--first-color);
    border-right: 4px solid var(--first-color);

}

.about-vector-one {
    width: 100%;
    filter: grayscale(100%) brightness(0%);
}

.about-us-team {
    margin-top: 2rem;
}

.about-team-title {
    text-align: left;
    margin-inline: 0;
    min-width: max-content;
}

.about-team-description {
    font-size: var(--h3-font-size);
    margin-bottom: 1rem;
    color: var(--title-color);
}

.about-team-list {
    list-style-type: none;
}

.about-team-list li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    font-size: 1.1em;
}

.about-team-list li:last-child {
    border-bottom: none; /* Removes the border from the last item */
}

.about_perfil {
    position: relative;
    justify-self: center;
    margin-block: 2.5rem 4.5rem;
}

.about_image {
    width: 220px;
}

.about_img {
    position: relative;
    border: 4px solid var(--white-color);
    z-index: 1;
}

.about_shadow,
.about_line,
.about_box {
    position: absolute;
}

.about_shadow {
    width: 150px;
    height: 385px;
    background-color: var(--body-color);
    top: -2.5rem;
    right: -3.5rem;
    border-bottom: 4px solid var(--first-color);
    transition: background-color .4s;
}

.about_perfil .geometric_box {
    top: 1.5rem;
    right: -2.8rem;
}

.about_line {
    filter: invert(1);
    right: -1.25rem;
    top: 6rem;
    width: 50px;
    transition: filter .4s;
}

.about_box {
    width: 40px;
    height: 40px;
    background-color: var(--first-color);
    right: -.5rem;
    bottom: 1.5rem;
}

.about_info {
    padding-left: 1.25rem;

}

.about_description {
    position: relative;
    color: var(--title-color);
    margin-bottom: 1.5rem;
}

.about_description::after {
    content: "";
    width: 20px;
    height: 1px;
    background-color: var(--title-color);
    position: absolute;
    left: -1.75rem;
    top: .5rem;
}

.about_list {
    list-style: square;
    color: var(--title-color);
    margin-bottom:  2rem;

}

.about_buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1rem;
}

.contact_me-btn {
    border: none;
    outline: none;
    font-size: var(--normal-font-size);
}

.button_ghost .about_icon {
    font-size: 1rem;
}

/* -------------------- BREAKDOWN POINT --------------------*/
/* For small devices */
@media screen and (max-width: 340px) {
    .about_image {
        width: 180px;
    }

    .about_shadow {
        height: 330px;
        right: -2.5rem;
    }

    .about_description {
        max-width: 270px;
    }
}

/* BREAK POINTS */
@media screen and (min-width: 768px) {
    .about-description-one {
        font-size: var(--h1-font-size);
        text-transform: uppercase;
        color: var(--title-color);
        text-align: center;
    }

    .about-description-two {
        font-size: var(--h2-font-size);
    }
}


/* For medium devices */
@media screen and (min-width: 576px) {
    .about_container{
        grid-template-columns: 350px;
        justify-content: center;
    }
}


/* For Large Devices */
@media screen and (min-width: 1150px) {
    .about_container {
        grid-template-columns: 440px 525px;
        gap: 1rem 9rem;
        align-items: center;
        padding-top: 4rem;
    }

    .about_perfil {
        order: -1;
        grid-row: 1 / 3;
    }

    .about_image {
        width: 350px;
    }

    .about_img {
        border: 8px solid var(--white-color);
    }

    .about_shadow {
        width: 240px;
        height: 615px;
        top: -4rem;
        right: -5.5rem;
        border-bottom: 6px solid var(--first-color);
    }

    .about_perfil .geometric_box {
        right: -4rem;
        top: 4.5rem;
    }

    .about_line {
        width: 80px;
        right: -2rem;
        top: 10rem;
    }

    .about_box {
        width: 64px;
        height: 64px;
        right: -.75rem;
    }

    .about_info {
        align-self: flex-start;
        padding-left: 3rem;
    }

    .about_description::after {
        width: 32px;
        height: 2px;
        left: -3rem;
        top: 14px;
    }

    .about_list {
        margin-bottom:  2rem;
    }

    .about_buttons {
        justify-content: initial;
    }

    .button_ghost {
        padding: .75rem;
    }

    .button_ghost .about_icon {
        font-size: 1.6rem;
    }
}



/* SHAPE ONE ------------------------------------------------------------------------------------- */
.shape-one {
    width: 200px;
    height: 200px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-block: 2.5rem;
    margin-inline: auto;
}

.shape-one .shape-one-quarter {
    width: 50%;
    height: 50%;
    position: relative;
}
.shape-one .shape-one-quarter::before, .shape-one .shape-one-quarter::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--dark-near-black);
}
.shape-one .shape-one-quarter::before {
    top: -25%;
    left: -25%;
}
.shape-one .shape-one-quarter::after {
    top: 25%;
    left: 25%;
}

/* For Large Devices */
@media screen and (min-width: 800px) {
    .shape-one {
        margin-block: 5rem;
        width: 350px;
        height: 350px;
    }
}