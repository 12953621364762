.product-viewer-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: smooth;
}

.product-viewer-container {
    position: relative;
    background-color: var(--container-color);
    padding-inline: .5rem;
    padding-block: 1.5rem;
}

.product-viewer-swiper {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pv-close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: var(--h3-font-size);
    border: none;
    outline: none;
    background-color: transparent;
    padding: .3rem;
    z-index: 100;
    transition: all .3s ease-in-out;
}

.pv-close-icon {
    color: var(--black-color);
    font-size: calc(var(--h1-font-size) + .5rem);
}

.product-viewer-close-icon:hover {
    color: var(--title-color);
}

.product-viewer-img {
    padding-block: 1rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-viewer-swiper {
    --swiper-navigation-size: var(--h1-font-size);
}

.product-viewer-swiper .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
}

@media (min-width: 768px) and (min-width: 768px) {
    .product-viewer-swiper {
        --swiper-navigation-size: calc(var(--h1-font-size) + .3rem);
    }

    .product-viewer-swiper .swiper-pagination-bullet {
        width: 9px;
        height: 9px;
    }
}

