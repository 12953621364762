.project-details-section {
    position: relative;
    background-color: var(--container-color);
    transition: background-color .4s;
}

.project-details-container {
    background-color: var(--body-color);
    padding-block: 2rem;
}

.project-details-title {
    text-align: center;
    position: relative;
    font-size: var(--h2-font-size);
    margin-bottom: 1.5rem;
}

/* pd-live-link ~~~~~ */
.pd-live-link {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: .7rem;
    margin-bottom: 1.5rem;
}

.pd-live-link:hover {
    background-color: var(--second-color);
}

.project-details-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.details-info-description {
    text-align: center;
    max-width: 400px;
}

.pd-slider-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    overflow: visible;
}

/* slider ------------------------ */
.pd-swiper-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.pd-slider-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.project-details-swiper {
    --swiper-navigation-size: var(--h1-font-size);
}
/* end */

.pd-feature-title {
    font-size: var(--h2-font-size);
    color: var(--title-color);
    margin-top: 1.5rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
}

.feature-title-icon {
    color: darkred;
}

.pd-feature-box {
    padding-block: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
}

.pd-feature-list {
    display: flex;
    row-gap: .3rem;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
}

.pd-feature-item {
    display: flex;
    align-items: center;
    font-size: var(--normal-font-size);
}

.pd-feature-icon {
    margin-inline: 6px;
    color: var(--second-color);
}

@media screen and (max-width: 1000px) {
    .project-details-container {
        background-color: var(--container-color);
    }
}

@media screen and (min-width: 1000px) {
    .project-details-section {
        padding-block: 2.5rem;
    }
    
    .pd-slider-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: max-content;
        column-gap: .5rem;
        justify-content: space-between;
        grid-template-areas: "slider info";
        padding-inline: .5rem;
    }

    .project-details-info {
        grid-area: info;
    }
    
    .pd-feature-box {
        padding-left: 1rem;
    }
}

@media screen and (min-width: 1200px) {
    .pd-slider-container {
        grid-template-columns: 1fr .5fr;
        grid-template-rows: 1fr;
    }
}

