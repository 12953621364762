/* -------------------- Projects --------------------*/
.recent-projects-section {
    background-color: var(--container-color);
    transition: background-color .4s;
    padding-block: 2rem;
}

.recent-projects-container {
    position: relative;
}

.recent-project-title {
    margin-bottom: 1.5rem;
}

.recent-projects-swiper {
    width: 100%;
}

.recent-projects-card {
    display: flex;
    flex-direction: column;
    row-gap: .8rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;
    background-color: var(--body-color);
    transition: background-color .4s;
    margin-bottom: 2rem;
}

.recent-projects-card:hover {
    background-color: var(--white-color);
}

.dark-theme .recent-projects-card:hover {
    background-color: var(--black-color);
}

.recent-projects-image {
    position: relative;
    margin-bottom: .5rem;
    overflow: hidden;
}

.recent-projects-button {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    padding: .5rem;
}

.recent-projects-button .arrow {
    font-size: var(--h3-font-size);
    transition: all .3s ease-in-out;
}

.recent-projects-button:hover .arrow {
    transform: rotate(-45deg);
}

.recent-projects-content {
    text-align: center;
}

.recent-projects-card-title {
    font-size: var(--h3-font-size);
}

.recent-projects-card-subtitle {
    position: relative;
    display: inline-block;
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    color: var(--text-color);
    margin-block: .5rem 1.5rem;
    text-wrap: wrap;
    width: 250px;
}

.recent-projects-card-subtitle::after {
    content: "";
    width: 20px;
    height: 1px;
    background-color: var(--text-color);
    position: absolute;
    top: 50%;
    left: -1.6rem;
}

.recent-projects-card-subtitle::before {
    content: "";
    width: 20px;
    height: 1px;
    background-color: var(--text-color);
    position: absolute;
    top: 50%;
    right: -1.6rem;
}

.recent-projects-swiper {
    --swiper-navigation-size: var(--h1-font-size);
}

.recent-projects-swiper .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
}

/* FOR LARGE DEVICES ---------------------------------------------------------  */
@media screen and (min-width: 768px) {
    .recent-projects-section {
        padding-block: 3rem;
    }

    .recent-projects-card {
        margin-bottom: 3rem;
    }

    .recent-projects-swiper .swiper-pagination-bullet {
        width: 9px;
        height: 9px;
    }
}

@media screen and (min-width: 1500px) {
    .recent-projects-card {
        width: 420px;
        height: 500px;
    }
    
    .recent-projects-button {
        right: 1.25rem;
    }
}













