/*  Projects -------------------------------------------------------------------------------------------------------- */
.projects-section {
    padding-block: 2rem;
    background-color: var(--container-color);
    transition: background-color .4s;
}
.projects-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.project-title {
    min-width: max-content;
}

.projects-card {
    position: relative;
    padding: .7rem;
    height: max-content;
    transition: background-color .4s;
    background-color: var(--body-color);
    box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;
}

.projects-card:hover {
    background-color: var(--white-color);
}

.dark-theme .projects-card:hover {
    background-color: var(--black-color);
}

.projects-card-image-box {
    position: relative;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.projects-card-img {
    width: 100%;
}

.projects_button {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    padding: .5rem;
    outline: none;
    border: none;
}

.projects_button .p_icon {
    font-size: 1.5rem;
}

.p_icon-hidden {
    transition: opacity 0.5s ease-in-out;
}

.p_icon-hidden {
    opacity: 0;
}

.project-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projects-card-subtitle {
    position: relative;
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    color: var(--text-color);
    margin-bottom: .40rem;
    padding-left: 1.75rem;
}

.projects-card-subtitle::after {
    content: "";
    width: 20px;
    height: 1px;
    background-color: var(--text-color);
    position: absolute;
    top: 50%;
    left: 0;
}

.projects-card-title {
    font-size: var(--h3-font-size);
    text-align: center;
    margin-bottom: .80rem;
}

.projects-card-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    margin-bottom: .5rem;
}

@media screen and (min-width: 768px) {
    .projects-container {
        grid-template-columns: repeat(2, 2fr);
    }
}

/* For Large Devices */
@media screen and (min-width: 1150px) {
    .projects-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .projects-card {
        height: 100%;
        padding: .8rem;
    }

    .projects-card-image-box {
        height: 400px;
        margin-bottom: 1rem;
    }
    
    .projects_button {
        right: 1.25rem;
    }
}


