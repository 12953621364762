/* Dropdown styling --------------------------------------------------------------------------------------------------*/
.custom-select {
    display: block;
    width: 100%;
    color: var(--text-color-light);
    padding-block: 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s;
}

.custom-select.selected {
    color: var(--title-color);
}

.custom-options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 3px solid var(--black-color);
    color: var(--first-color);
    border-radius: 5px;
    background-color: var(--container-color);
    z-index: 999;
}

.custom-option {
    padding: 10px;
    background-color: var(--container-color);
    color: var(--title-color);
    cursor: pointer;
}

.custom-option:hover {
    background-color: var(--black-color);
    color: var(--white-color);
}

.custom-select.open + .custom-options {
    display: block;
}

/* consultation styling ---------------------------------------------------------------------------------------------*/
.consultation-section {
    background-color: var(--container-color);
}

.consultation-container {
    position: relative;
    grid-template-rows: 1fr;
    background-color: var(--container-color);
    padding-inline: 0;
}

.consultation-data {
    position: relative;
    color: var(--white-color);
    width: 100%;
    text-align: center;
    line-height: 1.5rem;
    background-color: var(--contact-color);
    padding: 3rem 1.5rem 3.5rem;
    border-bottom: 4px solid #0071BC;
    transition: background-color .4s;
}

.consultation-data .geometric_box {
    top: 2rem;
    right: 2.5rem;
}

.consultation-subject {
    color: var(--white-color);
}

.consultation-subject {
    color: var(--white-color);
}


.consultation-data .geometric_box {
    background-color: #0071BC;
}

.consultation-data .geometric_box:after {
    border-color: var(--white-color);
}

.consultation-form-container {
    position: relative;
    background-color: var(--body-color);
    padding: 4rem 1rem 2rem;
    transition: background-color .4s;
}

.consultation-title {
    color: var(--white-color);
    font-size: var(--h2-font-size);
    margin-bottom: 2rem;
    text-align: center;
}

.consultation-title::after {
    background-color: hsla(204, 100%, 37%, 0.61);
}

.dark-theme .consultation-title::after {
    background-color: hsla(204, 100%, 37%, 0.61);
}

.consultation-form,
.consultation-group {
    display: grid;
    row-gap: 1.25rem;
}

.consultation-form {
    position: relative;
}

.consultation-box {
    position: relative;
    width: 100%;
    height: 58px;
}

.consultation-input,
.consultation-button {
    font-size: var(--normal-font-size);
    outline: none;
    border: none;
}

.consultation-input {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--body-color);
    border: 3px solid var(--black-color);
    color: var(--title-color);
    padding: .5rem 1rem;
    font-weight: var(--font-medium);
    transition: border .4s, background-color .4s;
}

.consultation-input::placeholder {
    color: var(--text-color-light);
    transition: opacity .4s;
}

.consultation-input:focus {
    border: 3px solid var(--first-color);
    color: black;
}

.consultation-input:valid {
    color: var(--title-color);
}

.consultation-input.invalid {
    border-color: white;
}

.consultation-input:checked {
    color: var(--text-color-light);
    transition: opacity .4s;
}

.consultation-input:-webkit-autofill {
    transition: background-color 6000s, color 6000s;
}

.consultation-label {
    position: absolute;
    top: 8px;
    left: 10px;
    color: var(--first-color);
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    background-color: var(--body-color);
    padding: 8px;
    pointer-events: none;
    transition: top .4s, opacity .4s, background-color .4s;
    opacity: 0;
}

.consultation-label.dari {
    right: 10px;
    left: auto;
}

.consultation-form .consultation-area {
    height: 10rem;
}

.consultation-area textarea {
    resize: none;
    padding-top: 1rem;
}

.consultation-button {
    background-color: var(--first-color);
    cursor: pointer;
}

.consultation-button:hover {
    color: black;
    background-color: var(--first-color-alt);
    cursor: pointer;
}

/* ~~~~ FOCUS PLACEHOLDER ~~~~ */
.consultation-input:focus::placeholder {
    opacity: 0;
}

/* ~~~~ MOVE LABEL UP & STICKY LABEL~~~~ */
.consultation-input:focus + .consultation-label,
.consultation-input:not(:placeholder-shown).consultation-input:not(:focus) + .consultation-label {
    opacity: 1;
    top: -16px;
}

.consultation-social {
    display: grid;
    row-gap: 1rem;
    background-color: hsl(0, 0%, 10%);
    padding-block: 1rem 2rem;
    text-align: center;
}

.consultation-social-arrow {
    width: 32px;
    margin: 0 auto;
}

.consultation-social-data {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.consultation-social-description-1 {
    color: var(--black-color-light);
    font-size: var(--small-font-size);
    margin-bottom: .5rem;
}

.consultation-social-description-2 {
    color: var(--white-color);
    font-size: var(--small-font-size);
    font-weight: var(--font-semi-bold);
}

.consultation-social-links {
    display: flex;
    justify-content: center;
    column-gap: .75rem;
}

.consultation-social-link {
    background-color: var(--black-color-light);
    padding: 6px;
    color: var(--white-color);
    font-size: 1.25rem;
    display: grid;
    place-items: center;
    transition: background-color .3s;
}

.consultation-social-link:hover {
    background-color: var(--first-color);
}

.consultation-message {
    color: var(--white-color);
    font-size: var(--small-font-size);
    position: absolute;
    bottom: -1.6rem;
    left: 0;
}

/* ---------------------------------------- */
.consultation-popup {
    text-align: left;
    font-size: var(--small-font-size);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.consultation-popup-text {
    color: var(--text-color-light);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.consultation-popup-icon {
    color: green;
}

/*  BREAKDOWN POINT -------------------------------------------------------------------------------- */
@media screen and (min-width: 576px) {

    .consultation-title {
        font-size: var(--h1-font-size);
    }
    
    .consultation-group {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
    }

    .consultation-social {
        background-color: transparent;
    }

    .consultation-social-arrow {
        filter: invert(1);
    }

    .consultation-social-description-1 {
        color: var(--text-color);
    }

    .consultation-social-description-2 {
        color: var(--title-color);
    }

    .dark-theme .consultation-social-arrow {
        filter: none;
    }
}

/* For Large Devices */
@media screen and (min-width: 1150px) {
    .consultation-container {
        padding-block:  3rem;
        max-width: 1000px;
    }

    .consultation-form-container {
        grid-column: 2 / 3;
        grid-row:  1 / 3;
        padding: 7rem 5.5rem 6rem 7.5rem;
        margin-top: 0;
    }
    

    .consultation-box {
        height: 60px;
    }

    .consultation-form .consultation-area {
        height: 11rem;
    }

    .consultation-button {
        margin-top:  1.2rem;
        width: max-content;
    }

    .consultation-data {
        padding: 4.5rem 3.5rem 3.5rem;
        border-bottom: 6px solid #0071BC;
    }

    .consultation-data .section-title-2 {
        margin: 0 0 2rem;
        transform: translateX(-6.5rem);
    }

    .consultation-message {
        bottom: -2.5rem;
    }

    .dark-theme .consultation-data {
        box-shadow: 4px 0 8px hsla(0, 0%, 4%, .3);
    }
}


.consultation-input-selected  {
    border-color: var(--first-color);
}


.custom-select-label {
    position: absolute;
    top: 8px;
    left: 10px;
    color: var(--black-color);
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    background-color: var(--body-color);
    padding: 8px;
    pointer-events: none;
    transition: top .4s, opacity .4s, background-color .4s;
    opacity: 0;
}

.custom-select-label {
    transition: opacity 0.3s ease, top 0.3s ease;
}

.custom-select-label.dari {
    left: auto;
    right: 10px;
}

.custom-select-label.active {
    opacity: 1;
    top: -16px;
}


.custom-select-label.red {
    color: var(--first-color);
}


/* Add this to your existing CSS file 
.consultation-box.selected .custom-select-input {
    border-color: var(--first-color);
}
*/

.custom-select-input:hover {
    border-color: var(--first-color);
}