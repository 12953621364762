.process-steps-section {
    padding-block: 50px 20px;
    background-color: var(--primary-dark-color);
}

.process-steps-description {
    text-align: center;
    font-size: var(--h3-font-size);
    color: var(--text-color);
}

.process-step-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 1rem;
}

.process-step-card {
    flex: 1 1 200px;
    max-width: 300px;
    padding-block: 20px;
    text-align: center;
}

.process-step-icon {
    display: block;
    margin-inline: auto;
    margin-bottom: .5rem;
    font-size: calc(var(--h1-font-size) + .4rem);
    color: #C9190B ;
}

.dark-theme .step-icon {
    color: var(--second-color);
}

.process-step-card h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: .7rem;
}

.step-description {
    font-size: 1em;
    color: var(--text-color);
}

@media screen and (min-width: 868px) {
    .process-step-content {
        justify-content: space-between;
    }
}