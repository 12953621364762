.skill-section {
    position: relative;
    background-color: var(--container-color);
    padding-block: 3rem;
    row-gap: 0;
}

.skill-container {
    position: relative;
    justify-content: center;
    row-gap: 0;
}

.skill-data {
    position: relative;
    background-color: var(--body-color);
    border-bottom: 4px solid var(--first-color);
    width: 100%;
    padding: .5rem;
    z-index: 5;
    transition: background-color .4s;
}

.skill-data .geometric_box {
    top: 2rem;
    right: 2.5rem;
}

.skill-description {
    text-align: center;
}

.skill-content {
    width: 100%;
    padding-block: 2rem;
    position: relative;
    background-color: var(--black-color);
    transition: background-color .4s;
}

.skill-icons {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: .2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.skill_icon {
    font-size: 4rem;
    padding-inline: .9rem;
    color: var(--text-color-light);
    border-radius: 50%;
    transition: .5s all ease-in-out;
}

.skill_icon:hover {
    border: 1px dotted var(--text-color-light);
    background-color: var(--text-color-light);
    color: black;
}

.skill_icon_container {
    position: relative;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.skill_name {
    position: absolute;
    top: -43px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--text-color-light);
    color: var(--black-color);
    padding: 10px 10px;
    border-radius: 5px;
    font-size: var(--small-font-size);
    font-weight: var(--font-semi-bold);
    white-space: nowrap;
    z-index: 10;
}

.skill_name::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
}

/* BREAKDOWN POINT -------------------------------------------------------------------------------------------------- */
@media screen and (min-width: 576px) {
    .skill-container {
        position: relative;
        grid-template-columns: 600px;
        padding-block: 5rem;
    }

    .skill-data {
        padding: 1rem;
        width: 322px;
        align-self: center;
        justify-self: center;
    }
}

@media screen and (min-width: 1200px) {
    .skill-container {
        grid-template-columns: .3fr 1fr;
        grid-template-rows: initial;
    }

    .skill-data {
        width: 444px;
        margin-right: -2rem;
    }

    .skill-content {
        padding-inline: 2rem;
        padding-block: 5rem;
    }
}

/* For Large Devices 
@media screen and (min-width: 1500px) {
    .skill-container {
        grid-template-columns: 300px 1000px;
        grid-template-rows: initial;
    }
}

 */