.four-O-four_section {
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: var(--container-color);
    user-select: auto;
}

.four-O-four_title {
    font-size: var(--big-font-size);
    justify-self: center;
}


@media screen and (min-width: 768px) {
    .four-O-four_title {
        font-size: calc(var(--big-font-size) + 3rem);
    }
}

@media screen and (min-width: 1150px) {
    .four-O-four_title {
        font-size: calc(var(--big-font-size) + 10rem);
    }
}