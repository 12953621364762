.floating-div {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
    background-color: var(--contact-color); /* Background color */
    border: 1px solid #cccccc; /* Border color */
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2000;
    
}

.floating_div-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: .3rem;
}

.floating_div-icon {
    font-size: var(--normal-font-size);
    color: var(--white-color);
}

.draggable-email {
    color: var(--white-color);
}

.draggable-btn {
    background-color: var(--first-color);
}