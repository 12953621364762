/* SERVICES --------------------------------------------------------------------------------------------------------- */
.services-section {
    position: relative;
    background-color: var(--container-color);
    padding-block: 2rem 2rem;
    transition: all .4s ease;
}

.service-title {
    margin-bottom: 2rem;
    min-width: max-content;
}

.services-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    row-gap: 3rem;
    margin-top: .2rem;
}

.service-description {
    display: inline-flex;
    justify-content: start;
    flex-wrap: wrap;
    width: 300px;
    text-overflow: ellipsis;
    overflow-wrap: break-word;

}

.services-card {
    position: relative;
    border-bottom: 1px solid var(--text-color-light);
    display: grid;
    max-width: 1000px;
    justify-self: center;
}

.services-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    position: relative;
    transition: background-color .4s, border 4s;
}

.services-icon {
    position: relative;
    display: inline-block;
}

.services-icon .services-icons {
    font-size: 3rem;
    color: var(--second-color);
    position: relative;
    z-index: 5;
    transition: color .4s, border 4s;
}

.services-box {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: var(--text-color-light);
    right: -2px;
    top: -2px;
    transition: all .3s ;
}

.services-title {
    font-size: var(--h3-font-size);
}

/* BREAK POINTS */
@media screen and (min-width: 768px) {
    .services-card {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
}

/* BREAK POINTS */
@media screen and (min-width: 1150px) {
    .services-content {
        padding: 3rem 1rem 3.5rem;
    }

    .services-icon i {
        font-size: 3rem;
    }

    .services-box {
        width: 32px;
        height: 32px;
    }

    .services-title {
        margin-bottom: 1.5rem;
    }
}

.services-vector {
    transition: filter 0.3s ease-in-out;
    cursor: grab;
}

.services-vector:hover {
    animation: grayscale-then-color 0.6s forwards;
}

@keyframes grayscale-then-color {
    0% {
        filter: none;
    }
    50% {
        filter: grayscale(100%);
    }
    100% {
        filter: none;
    }
}

.service-button {
    align-self: center;
    justify-self: center;
}