
.tc-contents {
    display: flex;
    flex-direction: column;
    row-gap: 1.3rem;
}

.tc-contents {
    padding-block: var(--regular-padding-block);
}
.tc-content-title {
    position: relative;
    display: inline-block;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color);
    margin-bottom: .30rem;
    padding-left: 1rem;
}

.tc-content-title::after {
    content: "";
    width: 10px;
    height: 1px;
    background-color: var(--text-color);
    position: absolute;
    top: 50%;
    left: 0;
}

.tc--content-description {
    padding-left: 1rem;
}

.tc-content-list {
    position: relative;
    list-style: square;
    padding-left: 3rem;
    padding-block: .3rem;
}

