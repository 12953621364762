/* language selector dropdown */
.language-selector {
    position: relative;
    font-weight: var(--font-semi-bold);
    background-color: var(--black-color);
    color: var(--white-color);
    height: 100%;
    width: fit-content;
    padding: .4rem;
    display: flex;
    align-items: center;
}

.language-selector .selected .language-selector-img {
    width: 20px;
}

.language-selector .options {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--black-color);
    margin-top: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.language-selector .options li {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: .4rem;
}

.language-selector .options li:hover {
    background-color: var(--text-color);
}
/* end */

/*  HEADER & NAV ----------------------------------------*/
.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--body-color);
    z-index: var(--z-fixed);
    transition: box-shadow .4s, background-color .4s;
    border-bottom: 1px solid var(--text-color-light);
}

.nav {
    position: relative;
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-logo {
    display: grid;
    place-items: center;
}

.nav-logo-img {
    width: 50px;
}

.nav-toggle {
    width: 32px;
    height: 32px;
    background-color: var(--black-color);
    color: var(--white-color);
    display: grid;
    place-items: center;
}


.nav-toggle {
    outline: none;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
}

/*  NAVIGATION FOR MOBILE DEVICES ---------------- */

@media screen and (max-width: 1150px) {
    .nav-menu {
        position: fixed;
        top: -1000%;
        left: 0;
        background-color: hsla(0, 0%, 0%, .75);
        width: 100%;
        padding-block: 1.8rem 5rem;
        text-align: center;
        backdrop-filter: blur(8px);
        transition: top .4s;
    }
}

.nav-title,
.nav-name,
.nav-close {
    color: var(--white-color);
}

.nav-title {
    display: block;
    font-size: var(--small-font-size);
    font-weight: var(--font-semi-bold);
    margin-bottom: 2rem;
}

.nav-name {
    position: relative;
    width: max-content;
    margin: 0 auto 2rem;
    font-size: var(--h2-font-size);
}

.nav-name::after,
.nav-name::before {
    content: "";
    width: 40px;
    height: 1px;
    background-color: var(--text-color-light);
    position: absolute;
    top: 50%;
    left: -4rem;
}

.nav-name::before {
    left: initial;
    right: -4rem;
}

.nav-list {
    display: flex;
    flex-direction:  column;
    align-items: center;
    justify-self: center;
    row-gap: 3rem;
}

.nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-link {
    position: relative;
    color: var(--text-color-light);
    font-size: 1.1rem;
    font-weight: var(--font-semi-bold);
    transition: color .3s;
}

.dropdown-nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .3rem;
}

.nav-link::after {
    content: "";
    width: 0;
    height: 2px;
    background-color: var(--white-color);
    position: absolute;
    left: 0;
    bottom: -.5rem;
    transition: width .3s;
}

.nav-link:hover {
    color: var(--white-color);
}

.nav-link:hover::after {
    width: 25%;
}

/* NAV BUTTON ~~~~~~~~~~ */
.nav-buttons {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.nav-button {
    position: relative;
    font-weight: var(--font-semi-bold);
    background-color: var(--first-color);
    color: var(--white-color);
    font-size: 1.1rem;
    padding: 1.1rem 1.5rem;
}

.nav-button::after {
    content: "";
    height: 2px;
    background-color: var(--first-color);
    position: absolute;
    left: 0;
    bottom: -.3rem;
    width: 0;
    transition: width .3s;
}

.nav-buttons .nav-button {
    display: none;
}

.nav-button:hover::after {
    width: 50%;
}

.active-nav-button {
    color: var(--white-color);
}

.nav-button:hover::after {
    width: 50%;
}

.active-nav-button::after {
    width: 50%;
}

.nav-close {
    background-color: transparent;
    outline: none;
    border: none;
    position: absolute;
    font-size: 1.5rem;
    top: 1rem;
    right: 1.5rem;
    cursor: pointer;
}

/* SHOW MENU ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.show-menu {
    top: 0;
    z-index: var(--z-tooltip);
}

/* ACTIVE LINK */

.active-link {
    color : var( --white-color )
}

.active-link::after {
    width: 25%;
}

.nav-them-button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-them-icon {
    font-size: 1.25rem;
    color: var(--title-color);
    cursor: pointer;
    transition: color .3s;
}

/* dropdown */
.nav-item.dropdown {
    position: relative;
    display: block;
    text-align: center;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    background-color: hsla(0, 0%, 0%, .75);
    padding: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
    z-index: 1;
    transition: all .3s ease-in-out;
}

.dropdown-link {
    position: relative;
}

.dropdown-icon {
    margin-left: .2rem;
    color: var(--white-color);
    font-size: var(--normal-font-size);
}

@media screen and (min-width: 1150px) {
    .dropdown {
        display: flex;
    }

    .dropdown-content {
        position: absolute;
        align-items: start;
        justify-content: start;
        background-color: var(--white-black);
        min-width: 140px;
        width: max-content;
        padding: 1.5rem;
        left: -15px;
        top: 3.2rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
        z-index: 1;
    }

    .dropdown-icon {
        color: var(--text-color);
        font-size: var(--h3-font-size);
    }

    .dropdown-icon:hover {
        color: var(--title-color)
    }
}
/* dropdown end */

/* For Large Devices ------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 1150px) {
    .nav-logo-img {
        width: 60px;
    }

    .nav {
        column-gap: 4rem;
    }

    .nav-close,
    .nav-toggle,
    .nav-title,
    .nav-name {
        display: none;
    }

    .nav-list .nav-button {
        display: none;
    }

    .nav-buttons .nav-button,
    .nav-buttons .language-selector {
        display: initial;
    }

    .nav-list {
        flex-direction: row;
        column-gap: 3rem;
    }

    .nav-menu {
        margin-left: auto;
    }

    .nav-link {
        color: var(--text-color);
    }

    .nav-link:hover {
        color: var(--title-color)
    }

    .nav-link::after {
        background-color: var(--title-color);
    }

    .active-link {
        color: var(--title-color);
    }

    .nav-them-icon {
        color: var(--text-color);
    }

    .nav-them-icon:hover {
        color: var(--title-color);
    }

    /* NAV BUTTON ~~~~~~~~~~ */
    .nav-button {
        position: relative;
        font-weight: var(--font-semi-bold);
        background-color: var(--black-color);
        color: var(--white-color);
        padding: 1.1rem 1.5rem;
    }

    .nav-button::after {
        content: "";
        height: 2px;
        background-color: var(--title-color);
        position: absolute;
        left: 0;
        bottom: -.3rem;
        width: 0;
        transition: width .3s;
    }

    .nav-button:hover {
        background-color: var(--black-color-light);
    }
}


