/* FONT --------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400..700&display=swap');


/* VARIABLES CSS ----------------------------------------*/
:root {
    --header-height: 3.5rem;

    /* COLOURS */
    --first-color: hsl(14, 98%, 50%);
    --first-color-alt: #FD774F;
    --second-color: #0071BC;
    --black-color: hsl(0, 0%, 0%);
    --black-color-light: hsl(0, 0%, 40%);
    --white-color: hsl(0, 0%, 95%);
    --title-color: hsl(0, 0%, 0%);
    --text-color: hsl(0, 0%, 35%);
    --text-color-light: hsl(0, 0%, 64%);
    --body-color: hsl(0, 0%, 87%);
    --container-color: hsl(0, 0%, 83%);
    --contact-color: hsl(0, 0%, 0%);
    --white-color-dim: #f2f2f2;
    --light-gray: #ccc;
    --white-black: #EBEBEB;


    /* FONT AND TYPOGRAPHY */
    /* .5rem = 8px | 1rem = 16px */
    --body-font: "Bai Jamjuree", sans-serif;
    --biggest-font-size: 2.5rem;
    --big-font-size: 10rem;
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /* FONT AND TYPOGRAPHY */
    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;
    
    /* Z-INDEX */
    --z-tooltip: 10;
    --z-fixed: 100;
}

:root {
    --primary-dark-color: #C9C9C9;
    --dark-near-black: hsl(0, 0%, 10%); /* Near Black */
}

/* RESPONSIVE TYPOGRAPHY ----------------------------------------*/
@media screen and (min-width: 1000px) {
    :root {
        --biggest-font-size: 3.5rem;
    }
}

@media screen and (min-width: 1250px) {
    :root {
        --biggest-font-size: 4.5rem;
    }
}

@media screen and (min-width: 1150px) {
    :root {
        --header-height: 5rem;
        --h1-font-size: 3rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}

/* BASE ----------------------------------------*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--body-color);
    color: var(--text-color);
    transition: background-color .4s /* for dark mode */;
}

[lang="fa"] {
    font-family: "El Messiri", sans-serif;
}

h1, h2, h3, h4 {
    color: var(--title-color);
    font-weight: var(--font-bold);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

/* THEME ----------------------------------------*/
/* VARIABLE DARK MODE */
body.dark-theme {
    /* COLOURS */
    --first-color: hsl(14, 80%, 50%);
    --second-color: #C9190B;
    --black-color: hsl(0, 0%, 18%);
    --black-color-light: hsl(0, 0%, 30%);
    --title-color: hsl(0, 0%, 95%);
    --text-color: hsl(0, 0%, 70%);
    --body-color: hsl(0, 0%, 8%);
    --container-color: hsl(0, 0%, 12%);
    --contact-color: hsl(0, 0%, 8%);
    --white-color-dim: #1A1A1A;
    --light-gray: black;
    --white-black: hsl(0, 0%, 12%) ;

    --primary-dark-color: #1A1A1A;

}

/*
COLOR CHANGE IN SOME PARTS OF
THE WEBSITE, IN DARK THEME
----------------------------------------------------------------------
 */
.dark-theme .shadow_header {
    box-shadow: 0 1px 12px hsla(0, 0%, 0%, .6);
}

.dark-theme .section_title-1::after {
    background-color: hsla(14, 70%, 50%, .2);
}

.dark-theme .section_title-2::after {
    background-color: hsla(0, 0%, 90%, 0.47);
}


.dark-theme :is(.home_arrow, .home_line, .about_line) {
    filter: none;
}

.dark-theme .home_description b {
    background: linear-gradient(90deg,
    hsla(14, 80%, 50%, .3),
    hsla(14 , 80%, 50%, 0));
}

.dark-theme :is(.button_ghost, .services_icon i) {
    color: var(--white-color);

}

.dark-theme .projects_card:hover {
    background-color: var(--body-color);
}

.dark-theme :is(.contact_mail, .contact_input, .contact_label) {
    background-color: var(--contact-color);
}

.dark-theme .footer {
    background-color: hsl(0, 0%, 4%);
}

.dark-theme::-webkit-scrollbar {
    background-color: hsl(0, 0%, 20%);
}

.dark-theme::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 30%);
}

.dark-theme::-webkit-scrollbar-thumb:hover {
    background-color: hsl(0, 0%, 40%);
}

/*  REUSABLE CSS CLASSES --------------------------------------------------------------------------------- */
.container {
    max-width: 1400px;
    margin-inline: auto;
    padding-inline: .7rem;
}

.grid {
    display: grid;
}

.section {
    padding-block: 4rem 2rem;
}

/* section titles ~~~~~~~~~~~~ */
.section_title-1 ,
.section_title-2 {
    position: relative;
    font-size: calc(var(--h1-font-size) - .1rem);
    margin-block: .9rem;
    margin-inline: auto;
    text-align: center;
    min-width: 200px;
    max-width: max-content;
    width: 100%;
}

.section_title-1 span,
.section_title-2 span {
    z-index: 5;
    position: relative;
}

.section_title-1::after {
    content: "";
    width: 40px;
    height: 28px;
    background-color: hsla(14, 98%, 50%, .2);
    position: absolute;
    top: -4px;
    right: -8px;
}

.section_title-2::after {
    content: "";
    width: 40px;
    height: 28px;
    background-color: RGBA(0,0,0,8%);
    position: absolute;
    top: -4px;
    right: -8px;
}

.section_title-2::after {
    top: initial;
    bottom: -4px;
}

@media screen and (min-width: 700px) {
    .section_title-1 ,
    .section_title-2 {
        font-size: var(--h1-font-size);
    }
}

/* ~~~~~~~~~~~~ */
.geometric_box {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: var(--first-color);
    rotate: -30deg;
}

.geometric_box:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border: 3px solid var(--black-color);
    left: -5px;
    top: -5px;
}

/*  BUTTONS --------------------------------------------------------------------------------------------------------- */
.button,
.button_ghost {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: .5rem;
}

.button {
    background-color: var(--black-color);
    padding: 1.1rem 1.5rem;
    color: white;
    font-weight: var(--font-medium);
    font-size: var(--normal-font-size);
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color .4s;
}


.button:hover {
    background-color: var(--first-color);
}

.button_ghost {
    border: 3px solid black;
    color: var(--black-color);
    padding: .5rem;
    transition: border .4s, color .4s;
}

.button_ghost i {
    font-size: 1.5rem;
}

.button_ghost:hover {
    border: 3px solid var(--first-color);

}

/* btn -------------------------------------------------------------------------------------------------------------- */
.btn {
    --border-color: linear-gradient(-45deg, hsl(14, 98%, 50%), #FD774F, hsl(14, 98%, 50%));
    --border-width: 2px;
    --curve-size: 0.5em;
    --blur: 30px;
    --bg: #080312;
    --color: #fff;
    color: var(--color);
    cursor: pointer;
    /* use position: relative; so that BG is only for .btn */
    position: relative;
    isolation: isolate;
    display: inline-grid;
    place-content: center;
    padding: 0.5em 1.5em;
    font-size: var(--h3-font-size);
    border: 0;
    text-transform: uppercase;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.6);
    clip-path: polygon(
        /* Top-left */
            0% var(--curve-size),
            var(--curve-size) 0,
                /* top-right */
            100% 0,
            100% calc(100% - var(--curve-size)),
                /* bottom-right 1 */ calc(100% - var(--curve-size)) 100%,
                /* bottom-right 2 */ 0 100%
    );
    transition: color 250ms;
}

.btn::after,
.btn::before {
    content: "";
    position: absolute;
    inset: 0;
}

.btn::before {
    background: var(--border-color);
    background-size: 300% 300%;
    animation: move-bg7234 5s ease infinite;
    z-index: -2;
}

@keyframes move-bg7234 {
    0% {
        background-position: 31% 0;
    }

    50% {
        background-position: 70% 100%;
    }

    100% {
        background-position: 31% 0;
    }
}

.btn::after {
    background: var(--bg);
    z-index: -1;
    clip-path: polygon(
        /* Top-left */ var(--border-width)
            calc(var(--curve-size) + var(--border-width) * 0.5),
            calc(var(--curve-size) + var(--border-width) * 0.5) var(--border-width),
                /* top-right */ calc(100% - var(--border-width)) var(--border-width),
            calc(100% - var(--border-width))
            calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
                /* bottom-right 1 */
            calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5))
            calc(100% - var(--border-width)),
                /* bottom-right 2 */ var(--border-width) calc(100% - var(--border-width))
    );
    transition: clip-path 500ms;
}

.btn:where(:hover, :focus)::after {
    clip-path: polygon(
        /* Top-left */ calc(100% - var(--border-width))
            calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
            calc(100% - var(--border-width)) var(--border-width),
                /* top-right */ calc(100% - var(--border-width)) var(--border-width),
            calc(100% - var(--border-width))
            calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
                /* bottom-right 1 */
            calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5))
            calc(100% - var(--border-width)),
                /* bottom-right 2 */
            calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5))
            calc(100% - var(--border-width))
    );
    transition: 200ms;
}

.btn:where(:hover, :focus) {
    color: #fff;
}

/*  SCROLL BAR ------------------------------------------------------------------------------------------------------ */
::-webkit-scrollbar {
    width: .6rem;
    border-radius: .5rem;
    background-color: hsl(0, 0%, 65%);
}

::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background-color: hsl(0, 0%, 55%);
}

::-webkit-scrollbar-thumb:hover {
    border-radius: .5rem;
    background-color: hsl(0, 0%, 45%);
}


/*  SCROLL UP ------------------------------------------------------------------------------------------------------- */
.scroll-up {
    position: fixed;
    right: 1rem;
    bottom: -50%;
    background-color: var(--black-color);
    display: inline-flex;
    padding: 8px;
    font-size: 1.25rem;
    color: var(--white-color);
    outline: none;
    border: none;
    z-index: var(--z-tooltip);
    transition: bottom .4s, transform .4s, background-color .4s;
}

.scroll-up:hover {
    transform: translateY(-.5rem);
}

/* ~~~~ SHOW SCROLL UP ~~~~ */
.show_scroll {
    bottom: 3rem;
}

body {
    /* Disable text selection for the entire body */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

/* Allow text selection for specific elements */
h1, h2, h3, h4, p, img {
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
}

/* -------------------- BREAKDOWN POINT --------------------*/
/* For small devices */

/* For Large Devices */
@media screen and (min-width: 1150px) {
    .section {
        padding-block: 7rem 2rem;
    }

    .section_title-1::after,
    .section_title-2::after  {
        width: 70px;
        height: 48px;
    }

    .geometric_box {
        transform: scale(1.2);
    }
}

/* -------- LOADER -------- */
.row_loader-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 3rem;
}

.loader, .loader:before, .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader {
    color: var(--text-color);
    font-size: 8px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}
.loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}
.loader:after {
    left: 3.5em;
}

@keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
}

/* LOADERS ---------------------------------------------------------------------------------------------------------- */
.loaders {
    color: #fff;
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
}

@keyframes mulShdSpin {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em,
        2em -2em 0 0, 3em 0 0 -1em,
        2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 -1em, -3em 0 0 -1em,
        -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
        3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 -1em, -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em,
        2em -2em 0 0, 3em 0 0 0.2em,
        2em 2em 0 0, 0 3em 0 -1em,
        -2em 2em 0 -1em, -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 0, 2em 2em 0 0.2em, 0 3em 0 0,
        -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 0, 0 3em 0 0.2em,
        -2em 2em 0 0, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
        -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 0, -3em 0 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 0, -3em 0 0 0, -2em -2em 0 0.2em;
    }
}

.skewed-button {
    background-color: #bfbfbf;
    color: black;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transform: skew(-20deg);
    display: inline-block;
    transition: background-color 0.3s, transform 0.3s;
}

.skewed-button:hover {
    background-color: #a0a0a0;
    transform: skew(-20deg) scale(1.05);
}

.skewed-button:focus {
    outline: none;
}


/* SHAPE TWO -------------------------------------------------------------------------------------------------------- */
.shape-two-container {
    text-align: center;
}
.shape-two {
    color: hsl(0, 0%, 0%);
    font-size: 70vw;
    margin: auto;
}

.dark-theme .shape-two {
    color: hsl(0, 0%, 64%);
}

@media screen and (min-width: 700px) {
    .shape-two {
        font-size: 25vw;
    }
}
