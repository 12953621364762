/* -------------------- HOME --------------------*/
.home-section {
    padding-block: 3rem;
    margin-top: var(--header-height);
}
.home-container {
    display: grid;
}

.home-title {
    font-size: calc(var(--h1-font-size) + .5rem);
    text-align: center;
    margin-bottom: 1rem;
}

.home-img-box {
    position: relative;
    display: flex;
    justify-content: center;
}

.home-img {
    width: 100%;
}

.home-info {
    margin-top: 3rem;
}

.home-scroll {
    width: max-content;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: .3rem;
}

.home-scroll-text {
    display: block;
    color: var(--text-color);
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.home-scroll-button {
    background-color: var(--black-color);
    color: var(--white-color);
    width: 36px;
    height: 36px;
    display: grid;
    place-items: center;
    font-size: 1rem;
    cursor: pointer;
    overflow: hidden;
    transition: background-color .4s;
}

.home-scroll-button .scroll-down-arrow {
    rotate: 45deg;
    animation: scroll-down 3s infinite;
}

/* ~~~~ ANIMATE SCROLL ICON ~~~~ */
@keyframes scroll-down {
    0% {
        transform: translateY(.6rem) ;
        opacity: 0;
    }

    50% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-1rem) ;
        opacity: 0 ;
    }
}

/* -------------------- BREAKDOWN POINT --------------------*/
/* For medium devices */

@media screen and (min-width: 600px) {
    .home-container {
        grid-template-columns: 1fr;
        justify-content: space-between;
    }
}

/* For Large Devices */
@media screen and (min-width: 1000px) {
    .home-container {
        grid-template-columns: 1fr 2fr;
    }
    
    .home-title {
        font-size: var(--biggest-font-size);
        text-align: left;
        margin: 0;
    }
}

/* For Large Devices */
@media screen and (min-width: 1144px) {
    .home-container {
        gap: 2rem 2rem;
        align-items: center;
        padding-block: 5.5rem;
    }
    
    .home-info {
        margin-top: 0;
        align-self: flex-start;
    }

    .home-scroll {
        margin: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: .75rem;
    }

    .home-scroll-button {
        width: 44px;
        height: 44px;
    }

    .home_social {
        left: initial;
        right: -6rem;
    }

    .home_social-link {
        font-size: 1.5rem;
        padding: .5rem;
    }

}

.home_arrow-right {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -110;
    width: 100%;
    color: white;
}