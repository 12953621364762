/* Breadcrumbs.css */
.breadcrumbs {
    margin-top: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-block: 2rem;
    font-size: var(--normal-font-size);
    font-weight: var(--font-bold);
}

.breadcrumbs-home {
    color: var(--text-color);
    transition: color .5s;
}

.breadcrumbs-home:hover {
    color: var(--first-color);
}

.breadcrumbs-items,
.breadcrumbs-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: .5rem;
}

.breadcrumb-icon {
    color: var(--first-color);
    font-size: var(--normal-font-size);
}

.breadcrumbs-link{
    color: var(--first-color);
    font-size: var(--normal-font-size);
}

@media screen and (min-width: 768px) {
    .breadcrumbs {
        padding-block: 3rem;
    }
}
