
.morocco_flag-content{
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
    flex-direction: column;
    padding-block: 2rem;
}

/* ~~~~~~~~~~~~~~~~ MOROCCO ~~~~~~~~~~~~~~~~ */
.morocco_tittle {
    position: relative;
    font-size: var(--h3-font-size);
    color: #222;
}

.morocco_tittle h2::before {
    content: attr(data-text);
    position: absolute;
    color: red;
    overflow: hidden;
    white-space: nowrap;
    border-right: 1px solid #D9D9D9;
    filter: drop-shadow(0 0 20px #fff) drop-shadow(0 0 50px red);
    animation: ani 8s linear infinite;
}

@keyframes ani {
    0%, 10%, 100% {
        width: 0;
    }
    70%, 90% {
        width: 100%;
    }
}

.morocco_flag {
    width: 100%;
   height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c1272d;
}

.morocco_flag-red {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    opacity: 85%;
    transition: all .6s ease-in-out;
}

.morocco_flag-emblem {
    width: 100px;
}
/*hover*/
.morocco_flag-emblem.morocco_flag-emblem-active {
    animation: oops .3s infinite alternate linear;

}

@keyframes oops {
    from {
        transform: scale(.7);
    }
    to {
        transform: scale(1);
    }
}

@media screen and (min-width: 800px) {
    .morocco_tittle {
        font-size: var(--biggest-font-size);
    }
    
    .morocco_flag {
        height: max-content;
        padding: 3rem;
    }
    
    .morocco_flag-emblem {
        width: 400px;
    }
}
