/* AlertModal.css */
.alert-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.alert-modal {
    background: var(--container-color);
    padding: 40px 20px;
    border-radius: 8px;
    text-align: center;
}

.alert-modal-message {
    font-size: var(--h2-font-size);
    margin-bottom: 20px;

}

.alert-modal-button {
    padding: 10px 30px;
    color: white;
    cursor: pointer;
}

