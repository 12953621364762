/* FOOTER ---------------------------------------- */
.footer {
    position: relative;
    padding-block: 2rem;
    overflow: hidden;
    color: white;
    background-color: var(--black-color);
}


.footer-container {
    display: grid;
    grid-template-columns: 100%;
}

.footer-data {
    padding-bottom: 2rem;
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer-logo {
    align-items: center;
    color: var(--title-color);
    margin-bottom: 1rem;
    width: 90px;
    border-radius: 2rem;
    transition: color .3s;
}

.footer-description {
    max-width: 230px;
    color: var(--text-color-light);
    overflow: hidden;
}

.footer-description {
    font-size: var(--small-font-size);
}

.footer-content,
.footer-list {
    display: grid;
}

.footer-content {
    grid-template-columns: repeat(2, max-content);
    gap: 2.5rem;
    width: 100%;
    padding-block: 1.5rem;
}

.footer-title {
    color: var(--text-color-light);
    font-size: calc(var(--normal-font-size) + .1rem);
    margin-bottom: .7rem;
}

.footer-list {
    row-gap: .5rem;
}

.footer-link {
    color: var(--text-color-light);
    font-size: var(--normal-font-size);
    cursor: pointer;
    transition: color .3s;
}

.footer-link:hover {
    color: var(--first-color);
}

.footer-social-list {
    display: flex;
    column-gap: 1.25rem;
}

.footer-social-link {
    color: var(--first-color);
    font-size: 1.25rem;
    transition: color .3s, transform .3s;
}

.footer-social-link:hover {
    transform: translateY(-2px);
}

.footer-contact-button {
    padding-inline: .8rem;
}

.footer-copy {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    font-size: var(--smaller-font-size);
    color: var(--text-color-light)
}

/* For medium devices */
@media screen and (min-width: 576px) {
    .footer-content {
        column-gap: 7rem;
    }
}

@media screen and (min-width: 668px) {
    .footer-content {
        grid-template-columns: repeat(3, max-content);
    }
}

@media screen and (min-width: 768px) {
    .footer {
        padding-block: 3.5rem;
    }
}

@media screen and (min-width: 1000px) {
    
    .footer-content {
        align-self: end;
        justify-self: end;
        grid-template-columns: repeat(4, max-content);
        justify-content: space-between;
    }
}

@media screen and (min-width: 1200px) {
    .footer-container {
        display: grid;
        grid-template-columns: 1fr 2fr;
        padding-bottom: 2rem;
    }

    .footer-data {
        padding-bottom: 2rem;
        justify-self: start;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        text-align: left;
    }
}




