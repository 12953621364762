.faq-section {
    background-color: var(--container-color);
    padding-block: 2rem;
}

.faq-title {
    text-align: center;
}

.faq-subtitle{
    padding-block: .9rem;
    font-size: var(--h2-font-size);
}

.accordion-title-icon {
    font-size: var(--h3-font-size);
    color: var(--second-color);
}

.faq-accordion-content {
    color: var(--text-color);
}

.faq-accordion {
    background-color: var(--body-color);
    color: var(--text-color);
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    padding: 1.5rem  ;
    outline: none;
    font-size: var(--normal-font-size);
    transition: 0.4s;
}

.active-faq-accordion,
.faq-accordion:hover {
    background-color: var(--light-gray);
    color: var(--text-color);
}

.faq-accordion:after {
    content: '\002B';
    color: var(--text-color);
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.active-faq-accordion:after {
    content: "\2212";
}

.faq-according-panel {
    padding: 0 18px;
    background-color: var(--white-color-dim);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.faq-according-panel-text {
    padding-block: 1rem;
}

@media screen and (min-width: 768px){
    .faq-section {
        padding-block: 3rem;
    }
    }